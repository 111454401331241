export const commonEnv = {
  assetBasePath: "https://cdn.takvb20.com/storage/aes-2021/final-content/",
  showName: "aes",
  fov: 75,
  persistentMenu: false,
  menuTitle: "Product Portfolio",
  menuType: "default",
  menuTooltips: false,
  footerType: "default",
  disablePhotosphereRotate: false,
  showHomeLink: false,
  homeLinkPhotosphereId: "home",
  showExitLink: true,
  exitLinkType: "photosphere",
  exitLinkValue: "center",
  showMenuButtonWhenHeaderVisible: false,
  autoOpenMenuOnHome: false,
  welcomeSettings: {
    countryGate: true,
    rememberHcpGate: true,
    title:
      "Welcome to the Takeda<br />Medical Affairs Virtual Booth Experience",
    veevaCode: "VV-MEDMAT-52596 12/2021",
    disclaimer:
      "The information on this website is intended for Healthcare Professionals ONLY.",
    footerCopy:
      "Copyright &copy;&nbsp;2021 Takeda Pharmaceutical Company Limited. All rights reserved.<br />TAKEDA and the TAKEDA logo are registered trademarks of Takeda Pharmaceutical Company Limited.<br />All other trademarks appearing herein are the property of their respective owners.",
    showFooter: true,
  },
  contentUrlRules: [
    {
      targetHosts: ["localhost"],
      contentUrl: "https://cdn.takvb20.com/storage/aes-2021/final-content",
    },
    {
      targetHosts: ["aes-stage-2021.ixm.websitemagic.dev"],
      contentUrl: "https://cdn.takvb20.com/storage/aes-2021/final-content",
    },
    {
      targetHosts: ["aes.medical.takedaneuroscience.com"],
      contentUrl: "https://cdn.takvb20.com/storage/aes-2021/final-content",
    },
  ],
};
