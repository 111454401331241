<div class="cover" [ngClass]="{ on: showCover }">
  <div class="preloader">
    <img src="../../../assets/images/takeda_logo.png" />
  </div>
</div>

<div id="photocover" class="cover2" [ngClass]="{ transition: showTransition }">
  <div class="preloader">
    <img src="../../../assets/images/spinner.png" class="transition" />
  </div>
</div>

<div [ngClass]="['photosphere', showPhotosphere ? 'on' : '']">
  <div id="container"></div>

  <div
    class="controls-prompt"
    [class.top-padding-increase]="currentPhotosphere.id === 'home'"
    [ngClass]="{ off: hasMoved }"
    *ngIf="!disablePhotosphereRotate"
  >
    <div *ngIf="currentPhotosphere.id !== 'home'">
      <img
        class="controls-prompt-icon"
        src="../../assets/images/handPrompt.png"
      />
      <p>Drag the view left and right to explore.</p>
    </div>
    <div>
      <div class="photosphere-flag static"></div>
      <p>Click on hotspots to learn more.</p>
    </div>
  </div>

  <div
    *ngIf="motionControlsPossible"
    class="controls"
    [ngClass]="{ off: !motionControls }"
    (click)="toggleMotionControls()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <path
        d="M32.97 5.03c6.53 3.1 11.22 9.45 11.93 16.97h3c-1.02-12.32-11.32-22-23.9-22-.45 0-.88.04-1.33.07l7.63 7.63 2.67-2.67zm-12.51-1.54c-1.17-1.17-3.07-1.17-4.24 0l-12.73 12.73c-1.17 1.17-1.17 3.07 0 4.24l24.04 24.04c1.17 1.17 3.07 1.17 4.24 0l12.73-12.73c1.17-1.17 1.17-3.07 0-4.24l-24.04-24.04zm9.2 38.89l-24.05-24.04 12.73-12.73 24.04 24.04-12.72 12.73zm-14.63.59c-6.53-3.1-11.22-9.45-11.93-16.97h-3c1.02 12.32 11.32 22 23.9 22 .45 0 .88-.04 1.33-.07l-7.63-7.63-2.67 2.67z"
      />
    </svg>
  </div>
</div>
