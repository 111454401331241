import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import jsonData from '../../assets/data/base/modals.base.json';
import { Modal } from '../models';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public modalVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public modalData: BehaviorSubject<any> = new BehaviorSubject(undefined);

  constructor(
    private router: Router,
    private util:UtilityService
  ) {}

  setActiveModalById(id: string) {
    const data: Modal = jsonData.find((el) => el.id === id) as Modal;
    this.setData(data);
  }

  setActiveModalByData(data: Modal) {
    this.setData(data);
  }

  private setData(data: Modal): void {
    console.log(data, "is the modalData");
    if (data && data.url_path) {
      this.router.navigate([ data.url_path ], { queryParams: {} });
    }
    // Update modal content source to use configured contentUrl for the project
    if (data && data.src) {
      data.src = this.util.expandContentUrl(data.src);
    }
    if (data && data.poster) {
      data.poster = this.util.expandContentUrl(data.poster);
    }
    this.modalVisible.next(true);
    this.modalData.next(data);
    
    // __TODO see if we need to add urls
    // if (data && data.url) {
    //     this.router.navigateByUrl(data.url);
    // }
  }

  hideModal() {

    this.router.navigate(['.'], { queryParams: {} });

    this.modalVisible.next(false);
    this.modalData.next(undefined);

    document.querySelectorAll('video').forEach((el) => {
      el.pause;
    });
  }
}
