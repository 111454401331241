<div class="redirect-to-external-link-modal">
  <h4>You are being redirected to</h4>
  <span class="link" (click)="onLinkClick()">{{ payload.href }}</span>
  <p>
    <i
      >Takeda has no influence or control over the content of third-party
      websites.</i
    >
  </p>
</div>
