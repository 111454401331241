import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Tracking from '../../assets/data/gtmEvents.json';
import { TrackingObject } from '../models';

declare const gtag: Function;
const dataLayer = window['dataLayer'];
//console.log(dataLayer, "is the dataLayer #2");
const prod =
  !(window.location.host.indexOf('localhost') > -1) &&
  !(window.location.host.indexOf('stage') > -1);
//const DEBUG = window.location.hostname !== "localhost";
const DEBUG = false;

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  constructor(private router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        console.log('##### Trigger a page_view');
        this.sendPageView();
      }
    });
  }

  public sendPageView(): void {
    if (DEBUG) {
      return;
    }
    let theEvent = {
      event: 'page_view',
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
    };
    if (gtag) {
      console.log(theEvent, '##### GTAG page view event');

      gtag('event', theEvent.event, theEvent);
    } else if (dataLayer) {
      dataLayer.push(theEvent);
    }
  }

  public sendGAEventById(id: any, overrides: any = undefined): void {
    let event: TrackingObject = Tracking.find((el) => el.id === id);
    if (!event) {
      console.log(`No tracking data found for id: ${id}`);
      return;
    } else {
      if (overrides) {
        event = Object.assign(event, overrides);
      }
    }
    console.log(event, '##### Is the ga event to track');
    this.sendGAEventByData(event);
  }

  public sendGAEventByData(event: TrackingObject): void {
    if (!event) {
      console.log('No tracking data found');
      return;
    }
    // strip out html from category/label
    let tmp;
    tmp = document.createElement('div');
    tmp.innerHTML = event.eventLabel;
    event.eventLabel = tmp.textContent || tmp.innerText || '';
    tmp = document.createElement('div');
    tmp.innerHTML = event.eventCategory;
    event.eventCategory = tmp.textContent || tmp.innerText || '';
    // Build and send the event
    const theEvent = {
      event: event.event,
      event_category: event.eventCategory,
      event_label: event.eventLabel,
    };
    if (event.eventValue) {
      theEvent['event_value'] = event.eventValue;
    }
    if (DEBUG) {
      return;
    }
    if (gtag) {
      gtag('event', theEvent.event, theEvent);
    } else if (dataLayer) {
      console.log(theEvent, 'GTM send event');
      dataLayer.push(theEvent);
    }
  }

  private getShowName(): string {
    return '';
    // return localStorage.getItem(`${environment.showName}-show-name`) || '';
  }
}
