import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ModalService, PhotosphereService } from "../../services";
import * as GTM from "../../utils/GTM";
import countries from "../../../assets/data/countryList.json";
import functionsList from "../../../assets/data/functionsList.json";
import Modals from "../../../assets/data/base/modals.base.json";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent implements OnInit {
  @Output() closeWelcomeOutput = new EventEmitter();

  public redirectUrl: string = "https://www.takeda.com/";
  public showRedirect: boolean = false;
  public showRedirecting: boolean = false;
  public showHealthcare: boolean = true;
  public showCountry: boolean = false;
  public countryPromptEnabled: boolean =
    environment.welcomeSettings.countryGate;
  public validCountries = countries;
  public validFunctions = functionsList;
  public selectedFunction: string = "";
  public email: string = "";
  public selectedCountry: string = "";
  public showWelcome: boolean = true;
  public showContinue: boolean = false;
  public areWeIframe: boolean = true;
  public environment: any = environment;

  constructor(
    private photosphereSvc: PhotosphereService,
    private modalSvc: ModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.areWeIframe = window.location !== window.parent.location;
  }

  public setRedirect(e): void {
    e.preventDefault();

    this.showRedirect = true;
    setTimeout(() => {
      this.showRedirecting = true;
      setTimeout(() => {
        window.location.href = this.redirectUrl;
      }, 1000);
    }, 5000);
    GTM.sendHCPEvent(false);
  }

  public setCountryPrompt(e): void {
    e.preventDefault();

    if (this.countryPromptEnabled) {
      this.showHealthcare = false;
      this.showCountry = true;
    } else {
      this.closeWelcome(null);
    }

    GTM.sendHCPEvent(true);
  }

  public closeWelcome(e): void {
    if (e) {
      e.preventDefault();
    }

    if (this.areWeIframe) {
      window.open(
        "https://www.entyviovirtualbooth.com/?t=236c5a5147bf7e0203b9e278ac5a94a4"
      );
      return;
    }

    this.showWelcome = false;

    this.closeWelcomeOutput.emit();

    localStorage.setItem("user-completed-hcp-gate", "1");

    if (this.countryPromptEnabled) {
      GTM.sendCountryEvent(this.selectedCountry);
    }
  }

  public setContinue(event): void {
    this.showContinue = event.target.value !== "none";
    this.selectedCountry = event.target.value;
  }

  public submitForm(form: NgForm): void {
    console.log(
      "FORM SUBMITTED",
      form.valid,
      this.selectedFunction,
      this.email
    );
    this.closeWelcomeOutput.emit();
    localStorage.setItem("user-completed-hcp-gate", "1");
  }
}
