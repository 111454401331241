<a
  *ngIf="!environment.persistentMenu"
  href="javascript:void(0);"
  class="side-nav-icon"
  (click)="openHeader($event, true)"
  [ngClass]="{ on: state !== 'open-state', off: hideIcon, shadow: showShadow }"
>
  <div class="triangle-open">
    <!-- <span class="menu-label">MENU</span> -->
  </div>
</a>

<div
  class="side-nav"
  [ngClass]="{
    on: environment.persistentMenu || state == 'open-state',
    persistent: environment.persistentMenu,
    'size-wider': environment.menuType === 'images',
    'has-tooltips': environment.menuTooltips
  }"
>
  <div class="side-tooltip-container" *ngIf="environment.menuTooltips">
    <div
      class="side-tooltip"
      *ngFor="let tooltip of toolTips; let idx = index"
      [id]="'tooltip_' + tooltip.id"
      [ngStyle]="{
        display: tooltip.show ? 'flex' : 'none',
        top: tooltip.y,
        left: tooltip.x,
        color: tooltip.fgColor,
        'background-color': tooltip.bgColor,
        'border-color': tooltip.borderColor
      }"
      (mouseleave)="tooltipHide($event, idx)"
    >
      <div class="tooltip-text" [innerHTML]="tooltip.text"></div>
    </div>
  </div>
  <div class="side-nav-inner">
    <div
      class="clickarea"
      (click)="openHeader($event, false)"
      *ngIf="environment.menuType === 'default'"
    >
      <div class="triangle-close" *ngIf="!environment.persistentMenu"></div>
      <a href="#" class="side-nav-logo">
        <img src="../../../assets/images/takeda_logo.png" alt="Takeda" />
      </a>
    </div>
    <div class="side-menu-items" *ngIf="environment.menuType === 'default'">
      <ng-container *ngFor="let menuItem of menuItems">
        <div
          *ngIf="menuItem.visible"
          class="side-nav-menu-item"
          (click)="onMenuItemClick(menuItem)"
          [ngClass]="{ on: currentPhotosphere == menuItem.targetId }"
        >
          <div
            class="side-box"
            [class.with-text]="menuItem.type == SideMenuItemTypes.Text"
            [class]="menuItem.cssClasses"
          >
            <img
              *ngIf="menuItem.type == SideMenuItemTypes.Image"
              [src]="menuItem.imageSrc"
              [alt]="menuItem.text"
            />
            <div
              *ngIf="menuItem.type == SideMenuItemTypes.Text"
              class="text"
              [innerHTML]="menuItem.text"
            ></div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="product-rows" *ngIf="environment.menuType === 'images'">
      <div class="product-portfolio">
        <!-- <div class="side-nav-item" (click)="setPhotosphere('hotspotGoToWelcomeArea')"
                    [ngClass]="{'on': currentPhotosphere.id === 'welcomeArea'}">
                    <span>{{ environment.menuTitle }}</span>
                </div> -->
        <div class="portfolio-title">
          {{ environment.menuTitle }}
        </div>
        <div class="portfolio-items">
          <ng-container *ngFor="let mi of menuItemsImages">
            <div
              *ngIf="mi.visible"
              class="side-nav-img"
              [ngClass]="{
                on: currentPhotosphere.id === mi.currentPhotosphereId
              }"
            >
              <div
                class="side-box"
                [ngClass]="mi.id"
                (click)="onMenuItemClick(mi)"
                (mouseenter)="tooltipShow($event, mi.tooltipIndex)"
                (mouseleave)="tooltipHide($event, mi.tooltipIndex)"
              >
                <img
                  [src]="mi.imageSrc"
                  alt=""
                  *ngIf="!environment.menuTooltips"
                />
                <div class="box-inner" *ngIf="environment.menuTooltips">
                  <div
                    class="box-sizer"
                    [ngStyle]="{
                      'background-image': 'url(' + mi.imageSrc + ')'
                    }"
                  >
                    <img
                      src="../../../assets/images/ddw/toolbar-menu-sizer.png"
                      alt=""
                    />
                  </div>
                  <div class="box-more">
                    <span class="more-title">{{ mi.moreTitle }}</span>
                    <span class="more-arrows"
                      ><svg-arrows [fillColor]="mi.arrowColor"></svg-arrows
                    ></span>
                  </div>
                </div>
                <div
                  *ngIf="mi.tooltipIndex > -1"
                  class="side-tooltip"
                  [attr.tooltipId]="'tooltip_' + toolTips[mi.tooltipIndex].id"
                >
                  <div
                    class="tooltip-text"
                    [innerHTML]="toolTips[mi.tooltipIndex].text"
                  ></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="special-area">
        <div class="special-inner">
          <ng-container *ngFor="let mi of menuItemsSpecial">
            <div
              class="side-nav-img"
              *ngIf="mi.visible"
              [ngClass]="{
                on: currentPhotosphere.id === mi.currentPhotosphereId
              }"
            >
              <div
                class="side-box with-text"
                (click)="onMenuItemClick(mi)"
                (mouseenter)="tooltipShow($event, mi.tooltipIndex)"
                (mouseleave)="tooltipHide($event, mi.tooltipIndex)"
              >
                <span [innerHTML]="mi.title"></span>
                <span class="subtitle" *ngIf="mi.subTitle">{{
                  mi.subTitle
                }}</span>
                <span class="arrow"><svg-arrows></svg-arrows></span>
                <div
                  *ngIf="mi.tooltipIndex > -1"
                  class="side-tooltip"
                  [attr.tooltipId]="'tooltip_' + toolTips[mi.tooltipIndex].id"
                >
                  <div
                    class="tooltip-text"
                    [innerHTML]="toolTips[mi.tooltipIndex].text"
                  ></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
