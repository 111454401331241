// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { commonEnv } from './environment.common';

export const environment = {
  production: false,
  assetBasePath: commonEnv.assetBasePath,
  showName: commonEnv.showName,
  fov: commonEnv.fov,
  persistentMenu: commonEnv.persistentMenu,
  menuTitle: commonEnv.menuTitle,
  menuType: commonEnv.menuType,
  menuTooltips: commonEnv.menuTooltips,
  footerType: commonEnv.footerType,
  disablePhotosphereRotate: commonEnv.disablePhotosphereRotate,
  showHomeLink: commonEnv.showHomeLink,
  showExitLink: commonEnv.showExitLink,
  exitLinkType: commonEnv.exitLinkType,
  exitLinkValue: commonEnv.exitLinkValue,
  homeLinkPhotosphereId: commonEnv.homeLinkPhotosphereId,
  showMenuButtonWhenHeaderVisible: commonEnv.showMenuButtonWhenHeaderVisible,
  autoOpenMenuOnHome: commonEnv.autoOpenMenuOnHome,
  welcomeSettings: commonEnv.welcomeSettings,
  contentUrlRules: commonEnv.contentUrlRules
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
