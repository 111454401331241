import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import Photospheres from '../assets/data/base/photospheres.base.json';
import Modals from '../assets/data/base/modals.base.json';
import {
  MedicalRequestFormWrapperService,
  ModalService,
  PhotosphereService,
  SideMenuState,
  GoogleService,
} from './services';
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  Router,
  UrlSegment,
} from '@angular/router';
import { Modal, Photosphere, TrackingObject } from './models';
import { SideMenuComponent } from './components';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('sideNav')
  private sideNav: SideMenuComponent;

  public environment: any = environment;

  public initialLoad: boolean = true;

  public event: string = '';
  public success: boolean = false;
  public showForm: boolean = false;
  public weLoaded: boolean = false;

  public currentPhotosphere: Photosphere = undefined;
  public photosphereToLoad: Photosphere = undefined;
  public photosphereActive: boolean = false;

  public magicKey: boolean = true;
  public showWelcome: boolean = false;
  public desktop: boolean = true;
  public mobile: boolean = true;

  // __DEBUG this flag will hide or show the landing page vs the whole experience
  // public showlanding: boolean = true;
  public showlanding: boolean = false;

  public autoLoadModal: any = false;

  constructor(
    private photosphereSvc: PhotosphereService,
    private modalSvc: ModalService,
    private activatedRoute: ActivatedRoute,
    private medicalRequestFormWrapperSvc: MedicalRequestFormWrapperService,
    private router: Router,
    private googleSvc: GoogleService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params?.h && params.h === 'efdc8aa869642809b0f0132706218f0a') {
        this.magicKey = true;
        localStorage.setItem('hi-magic-key', '1');
      } else {
        if (
          localStorage.getItem('hi-magic-key') &&
          localStorage.getItem('hi-magic-key') === '1'
        ) {
          this.magicKey = true;
        }
      }

      // Skip welcome
      if (
        localStorage.getItem('skip-welcome') &&
        localStorage.getItem('skip-welcome') === '1'
      ) {
        this.disableWelcomeView();
      }
    });

    // Check url path and open photospheres or modals depending on route
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        let segments = val.url.split('/').filter((el) => {
          return el !== '';
        });

        console.log(val.url, segments, 'are url and the segments');

        if (this.initialLoad) {
          this.initialLoad = false;
          this.photosphereToLoad = Photospheres.find(
            (el) => el.url === segments[0]
          );
        } else {
          this.photosphereSvc.setCurrentPhotosphere(segments[0]);
        }

        if (val.url) {
          let match_url = val.url;
          if (match_url.indexOf('?') > 0) {
            match_url = match_url.substring(0, match_url.indexOf('?'));
          }
          console.log(match_url, 'match_url');
          this.autoLoadModal = Modals.find((el) => {
            return el['url_path'] === match_url;
          });
        }

        // __TODO maybe add in modals as well?
        return;
        if (segments.length > 1) {
          const modal: Modal = Modals.find(
            (el: any) => el.id === segments[segments.length - 1]
          ) as Modal;

          if (modal) {
            modal.parent = modal.parent
              ? modal.parent
              : segments[segments.length - 2];
            this.modalSvc.setActiveModalByData(modal);
          }
        } else if (
          segments[0] === 'welcome' ||
          segments[0] === 'resource-library'
        ) {
          this.modalSvc.setActiveModalById(segments[0]);
        } else {
          this.modalSvc.hideModal();
        }
      }
    });
  }

  ngOnInit(): void {
    if (environment.welcomeSettings.rememberHcpGate) {
      this.showWelcome = !localStorage.getItem('user-completed-hcp-gate');
    } else {
      this.showWelcome = true;
    }

    if (!this.showWelcome) {
      this.twilioVisibility(true);
    }

    window.addEventListener('resize', this.onWindowResize.bind(this), false);
    this.medicalRequestFormWrapperSvc.init();
    this.onWindowResize();

    this.photosphereSvc.photosphereLoaded.subscribe((data) => {
      if (data === true) {
        this.photosphereSvc.setCurrentPhotosphere(
          this.photosphereToLoad
            ? this.photosphereToLoad.id
            : Photospheres[0].id
        );
        this.weLoaded = true;
        this.photosphereSvc.interactable.next(true);
      }
    });

    window.addEventListener(
      'hiTwilioChatEvent',
      this.handleTwilioEvent.bind(this)
    );
  }

  // public onWindowResize(): void {
  //   this.desktop = window.innerWidth > 767 && window.innerHeight > 649;
  //   this.mobile =
  //     !this.desktop && window.innerWidth > 699 && window.innerHeight > 559;
  // }

  public onWindowResize(): void {
    this.desktop = window.innerWidth > 430;
    this.mobile = !this.desktop;
  }

  public disableWelcomeView(): void {
    this.showWelcome = false;
    this.sideNav.setState(SideMenuState.OPEN); // open the side menu after welcome modal is closed
    this.photosphereSvc.interactable.next(true);
    this.twilioVisibility(true);
    if (this.autoLoadModal) {
      this.modalSvc.setActiveModalById(this.autoLoadModal.id);
    }
  }

  /*
  @HostListener('hiTwilioChatEvent', ['$event']);
  */
  public handleTwilioEvent(e: any) {
    console.log(e.detail, 'is the hiTwilioChatEvent event detail.');
    if (e.detail && e.detail.eventName && e.detail.eventData) {
      let event: TrackingObject = {
        id: 'chat',
        event: 'twilio-chat',
        eventCategory: e.detail.eventName,
        eventLabel: e.detail.eventData,
      };
      this.googleSvc.sendGAEventByData(event);
    }
  }

  public twilioVisibility(is_visible: boolean) {
    (document.querySelector('#hiTwilioChat') as HTMLElement).style.display =
      is_visible ? 'block' : 'none';
  }
}
