import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { environment } from 'src/environments/environment';
import * as GTM from '../../utils/GTM';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {
  @Input() data: any;
  // public path: string = environment.assetBasePath + 'videos/';
  public images = [];
  public hitAreas = [];
  public currentSlide = 0;

  constructor(private modalSvc: ModalService) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    for (let i = 0; i < this.data.numSlide; i++) {
      this.images.push(this.data.basePath + this.data.baseName + i + '.jpg');
    }
    this.currentSlide = 0;
    this.setHitAreas();
  }

  public imageHotSpotClick(e): void {
    if (this.hitAreas.length > 0) {
      console.log(this.hitAreas);
      const ratio = e.target.width / this.hitAreas[0].slideWidth;
      const oX = e.offsetX / ratio;
      const oY = e.offsetY / ratio;
      this.hitAreas.forEach(spot => {
        console.log('this.currentSlide', spot);
        if (spot.slideId === this.currentSlide) {
          if (oX > spot.x && oX < spot.x + spot.w) {
            if (oY > spot.y && oY < spot.y + spot.h) {
              if (spot.link.type === 'nav') {
                if (spot.link.url === 'next') {
                  this.nextSlide();
                } else if (spot.link.url === 'previous') {
                  this.prevSlide();
                }
              } else if (spot.link.type === 'url') {
                GTM.sendGTMEvent(spot.link.gtm_id);
                window.open(spot.link.url, '_blank');
              } // END IF
            }
          }
        }
      });
    }
  }

  public setHitAreas(): void {
    if (this.data.hitAreas) {
      this.hitAreas = this.data.hitAreas.filter(area => area.slideId === this.currentSlide);
    }
  }

  public nextSlide(): void {
    if (this.currentSlide + 1 < this.images.length) {
      this.currentSlide += 1;
      this.setHitAreas();
    }
  }

  public prevSlide(): void {
    if (this.currentSlide - 1 >= 0) {
      this.currentSlide -= 1;
      this.setHitAreas();
    }
  }

  public dotClick(e, i): void {
    e.preventDefault();

    this.currentSlide = i;
  }
}
