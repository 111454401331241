<div
  *ngIf="magicKey"
  [class.mobile]="mobile" [class.desktop]="desktop"
  [ngClass]="[(environment.persistentMenu ? 'persistent-menu' : ''), ('menu-type-' + environment.menuType), ('footer-type-' + environment.footerType)]"
>
  <app-landing *ngIf="!weLoaded"></app-landing>

  <ng-container *ngIf="!showlanding">
    <app-header [ngClass]="{ on: !showWelcome }"></app-header>
    <app-side-nav #sideNav [ngClass]="{ on: !showWelcome }"></app-side-nav>
    <app-welcome
      *ngIf="!showForm"
      class="app-welcome"
      [ngClass]="{ on: showWelcome }"
      (closeWelcomeOutput)="disableWelcomeView()"
    ></app-welcome>
    <app-photosphere></app-photosphere>
    <app-modal></app-modal>
    <app-footer [hideChat]="showWelcome"></app-footer>
  </ng-container>
</div>

<!-- <div
  *ngIf="!desktop && !mobile && magicKey"
  class="unsupported-screen-size-warning"
> -->

<div
  *ngIf="mobile"
  class="unsupported-screen-size-warning"
>
  <div>
    <div class="shadowbox hideItAll">
      <div class="hi-modal">
        <div class="screensize-content">
          <img class="welcome-logo" src="../assets/images/takeda_logo.png" />
          <h3>For Best Viewing Experience</h3>

          <p class="modal-copy">
            Please view on a larger display, at full screen or adjust display
            settings in your&nbsp;browser
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!magicKey">
  <div>
    <div class="shadowbox">
      <div class="hi-modal">
        <div class="screensize-content">
          <img class="welcome-logo" src="../assets/images/takeda_logo.png" />
          <h3>Permission Denied</h3>
        </div>
      </div>
    </div>
  </div>
</div>
